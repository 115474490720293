<template>
  <b-overlay :show="loading">
    <b-form @submit.prevent="setTeamsSync">
      <b-row>
        <!-- Field: Firstname -->
        <b-col cols="12" md="12">
          <b-form-group label="Tenant Id" label-for="tenantId">
            <b-form-input
              id="tenantId"
              :class="$v.data.tenantId.$error ? 'is-invalid' : ''"
              v-model="data.tenantId"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12">
          <b-form-group label="Client Id" label-for="clientId">
            <b-form-input
              id="clientId"
              :class="$v.data.clientId.$error ? 'is-invalid' : ''"
              v-model="data.clientId"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12">
          <b-form-group label="Client Secret" label-for="clientSecret">
            <b-form-input
              id="clientSecret"
              :class="$v.data.clientSecret.$error ? 'is-invalid' : ''"
              v-model="data.clientSecret"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Match gebruikers automatisch met elkaar">
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-form-checkbox
                  v-model="data.autoMatchUsers"
                  plain
                  name="checkbox-input"
                />
              </b-input-group-prepend>
              <b-form-select
                :disabled="!data.autoMatchUsers"
                v-model="data.autoMatchField"
                :options="matchOptions"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Maak een Team aan bij het aanmaken van een Tapp-groep"
          >
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-form-checkbox
                  v-model="data.autoCreateTeams"
                  plain
                  name="checkbox-input"
                />
              </b-input-group-prepend>
              <b-form-select
                :disabled="!data.autoCreateTeams"
                v-model="data.defaultTeamsOwner"
                :options="orgAdmins"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <div v-if="data.id">
          <b-col cols="12" :key="`role-${role}`" v-for="role in userRoles">
            <b-form-group
              :label="`Maak een Microsoft 365 gebruiker aan voor elke ${getRoleSingular(
                role
              )}`"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-form-checkbox
                    v-model="data.roles[role].autoCreateUsers"
                    plain
                    name="checkbox-input"
                  />
                </b-input-group-prepend>
                <b-form-select
                  :disabled="!data.roles[role].autoCreateUsers"
                  v-model="data.roles[role].structure"
                  :options="usernameOptions"
                />

                <b-input-group-append>
                  <b-form-select
                    :disabled="!data.roles[role].autoCreateUsers"
                    v-model="data.roles[role].domain"
                    :options="data.domains"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </div>
      </b-row>
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="submit"
        :disabled="loading"
      >
        Opslaan
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        @click="resetData"
        :disabled="loading"
      >
        Annuleren
      </b-button>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BOverlay,
} from "bootstrap-vue";
import { functions, db } from "@/firebase/firebaseConfig";
import { required } from "vuelidate/lib/validators";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import mainMixin from "@/mixins/mainMixin";

export default {
  mixins: [mainMixin],
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BForm,
    BOverlay,
  },
  props: {
    config: {},
  },
  data() {
    return {
      loading: false,
      data: {
        clientId: null,
        clientSecret: null,
        tenantId: null,
        autoMatchUsers: true,
        autoMatchField: "displayName",
        autoCreateTeams: true,
        defaultTeamsOwner: null,
        roles: {
          leader: {
            autoCreateUsers: false,
            domain: null,
            structure: "full",
          },
          member: {
            autoCreateUsers: false,
            domain: null,
            structure: "short",
          },
          admin: {
            autoCreateUsers: false,
            domain: null,
            structure: "full",
          },
        },
        type: "m365",
      },
      matchOptions: [
        { value: "displayName", text: "Weergavenaam" },
        // { value: "email", text: "E-mailadres" },
      ],
      usernameOptions: [
        { value: "short", text: "voornaam.f" },
        { value: "full", text: "voornaam.familienaam" },
      ],
      ownerOptions: [
        { value: null, text: "Kies een standaard eigenaar van het Team" },
      ],
    };
  },
  created() {
    if (this.config != null) {
      this.data = { ...this.config, ...{} };
    }
  },
  watch: {
    $props: {
      handler() {
        if (this.config != null) {
          this.data = { ...this.config, ...{} };
        }
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    setTeamsSync() {
      if (!this.$v.$invalid) {
        this.loading = true;
        db.collection("organisations")
          .doc(this.$store.state.organisation.id)
          .collection("integrations")
          .doc("m365")
          .set(this.data, { merge: true })
          .then((res) => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Synchronisatie gestart!`,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.$emit("input", res);
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error!`,
                text: error.message,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      } else {
        this.$v.$touch();
      }
    },
    resetData() {
      this.$emit("input", null);
      this.data = {
        clientId: null,
        clientSecret: null,
        tenantId: null,
        type: "m365",
      };
    },
  },
  computed: {
    orgAdmins() {
      if (this.$store.state.userManagement.users.length > 0) {
        return [
          ...this.ownerOptions,
          ...this.$store.state.userManagement.users
            .filter((item) => item.type == "admin")
            .map((item) => {
              return { value: item.uoid, text: item.displayName };
            }),
        ];
      } else {
        this.$store.dispatch("userManagement/fetchUsers");
        return this.ownerOptions;
      }
    },
  },
  validations: {
    data: {
      clientId: {
        required,
      },
      clientSecret: {
        required,
      },
      tenantId: {
        required,
      },
    },
  },
};
</script>

<style>
</style>