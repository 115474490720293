<template>
  <b-overlay :show="loading">
    <b-form @submit.prevent="setInformatSync">
      <b-row>
        <!-- Field: Firstname -->
        <b-col cols="12" md="12">
          <b-form-group label="Gebruikersnaam" label-for="username">
            <b-form-input
              id="username"
              :class="$v.data.username.$error ? 'is-invalid' : ''"
              v-model="data.username"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12">
            
          <b-form-group label="Wachtwoord" label-for="password">
               <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="password"
                    v-model="data.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :class="$v.data.password.$error ? 'is-invalid' : ''"
                    name="login-password"
                    placeholder="Wachtwoord"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
            
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12">
          <b-form-group label="Instellingsnummer" label-for="number">
            <b-form-input
              id="number"
              :class="$v.data.instellingsnummer.$error ? 'is-invalid' : ''"
              v-model="data.instellingsnummer"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12">
            <b-form-group label="Schooljaar" label-for="schooljaar">
              <v-select
                v-model="data.schooljaar"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="loading"
                :closeOnSelect="true"
                :options="schooljaren"
              />
            </b-form-group>
          </b-col>
      </b-row>
      <b-button
        variant="primary"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="submit"
        :disabled="loading"
      >
        Opslaan
      </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        @click="resetData"
        :disabled="loading"
      >
        Annuleren
      </b-button>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BOverlay,
} from "bootstrap-vue";
import vSelect from 'vue-select'
import { functions } from "@/firebase/firebaseConfig";
import { required, email, minLength, numeric } from "vuelidate/lib/validators";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    vSelect
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      data: {
        organisationId: this.$store.state.organisation.id,
        organisationName: this.$store.state.organisation.shortName,
        username: "",
        password: "",
        schooljaar: "2021-22",
        instellingsnummer: "",
        hoofdstructuur: "",
      },
      schooljaren: [
          { label: "2020-2021", value: '2020-21' },
          { label: "2021-2022", value: '2021-22' },
      ],
    };
  },
  methods: {
    resetData() {
      this.$emit('input', null)
      this.data = {
        organisationId: this.$store.state.organisation.id,
        organisationName: this.$store.state.organisation.shortName,
        username: "",
        password: "",
        schooljaar: "2021-22",
        instellingsnummer: "",
        hoofdstructuur: "",
      };
    },
    setInformatSync() {
      this.loading = true;
      functions
        .httpsCallable("setNewInformatSync")(this.data)
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Synchronisatie gestart!`,
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit("input", null);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Error!`,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  validations: {
    data: {
      username: {
        required,
      },
      password: {
        required,
      },
      instellingsnummer: {
        required,
      },
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>